import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import Content, { HTMLContent } from "../components/Content";
import Layout from "../components/Layout";
import SocialLinks from "../components/SocialLinks";
import ContactBanner from "../components/ContactBanner";

const IndexPageStyles = styled.div`
  flex-direction: column;
  .title-wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 769px) {
      align-items: center;
    }
  }
  .title-text {
    order: 2;
  }
  .social-links-wrapper {
    order: 1;
  }

  .gatsby-resp-image-figure {
    border: 2px solid #dad6d6;
    img {
      padding: 5px 5px 0;
    }
    figcaption {
      font-style: italic;
      text-align: left;
      padding: 0 5px 5px;
    }
  }
  @media screen and (max-width: 768px) {
    .title-wrapper > div {
      padding-top: 0;
      padding-bottom: 0;
    }
    .gatsby-resp-image-figure {
      margin: 0 auto;
      float: none;
      width: 100%;
    }
  }

  @media screen and (min-width: 769px) {
    .title-wrapper {
      flex-direction: row;
    }
    .title-text {
      order: 1;
    }
    .social-links-wrapper {
      order: 2;
    }
    .gatsby-resp-image-figure {
      float: right;
      margin: 0 20px;
      width: 350px;
    }
  }
`;
export const IndexPageTemplate = ({
  content,
  headerImage,
  contentComponent,
  subheading
}) => {
  const PageContent = contentComponent || Content;
  return (
    <section className="section section--gradient">
      <IndexPageStyles>
        {headerImage?.gatsbyImageData && (
          <GatsbyImage
            image={headerImage?.gatsbyImageData}
            className="is-hidden-tablet"
            alt="Rwakobo Rock"
            alt="Title"
            // fluid={headerImage.childImageSharp.fluid}
            // title="Rwakobo Rock"
            // aspectRatio={1}
            // sizes={{
            //   src: headerImage.childImageSharp.fluid.src,
            //   srcSet: headerImage.childImageSharp.fluid.srcSet,
            //   sizes: "1500 600",
            //   aspectRatio: 2.67
            // }}
            style={{
              objectFit: "cover",
              width: "112%",
              marginLeft: "-6%"
            }}
          ></GatsbyImage>
        )}
        <ContactBanner />
        <div className="columns title-wrapper">
          <div className="title-text column is-8">
            {/* <h1 className="has-text-weight-semibold is-size-6">{heading}</h1> */}
            <h1 className="has-text-weight-semibold is-size-5">{subheading}</h1>
          </div>
          <SocialLinks className="social-links-wrapper"></SocialLinks>
        </div>

        <div className="columns">
          <div className="column is-12">
            <PageContent className="content" content={content} />
          </div>
        </div>
      </IndexPageStyles>
    </section>
  );
};

IndexPageTemplate.propTypes = {
  headerImage: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  title: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  heading: PropTypes.string,
  subheading: PropTypes.string
};

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = post;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        headerImage={frontmatter.headerImage}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        headerImage {
          childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
              }
        }
        heading
        subheading
      }
    }
  }
`;
